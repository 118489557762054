import React, { useState } from 'react';
import PropTypes from 'prop-types';

import LeftAd from './elements/LeftAd';
import Condominium from './elements/Condominium';
import TopBar from './elements/TopBar';
import PublicTransport from './elements/PublicTransport';

const View = ({ data, ...props }) => {
  const [adAspectRatio] = useState(false); // False to disable, use values like "2/3" or "1/1"

  return (
    <div className="flex flex-col w-full h-screen max-w-full">
      <div className="flex flex-shrink-0 items-center h-24 shadow-opasti z-50">
        <TopBar location={data.location} />
        {data.topcorner && <PublicTransport {...data.topcorner} />}
      </div>
      <div className="flex flex-grow w-full h-full">
        <LeftAd className="w-1/2" ads={data.ads} aspectRatio={adAspectRatio} />
        <div className="flex justify-center w-1/2 ">
          <Condominium
            className="w-full text-center"
            news={data.news}
            residentList={data.residentList}
            infobar={data.infobar}
          />
        </div>
      </div>
    </div>
  );
};

View.propTypes = {
  data: PropTypes.object,
};

View.defaultProps = {
  data: {},
};

export default View;
